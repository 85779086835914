
.ui-dialog {
    background: #fff;
    border: 1px solid #eee;
}

.ui-widget-overlay {
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    opacity: 0.3 !important;
    background: #000 !important;
}

.ui-widget {
    font-family: Verdana,Arial,sans-serif;
    font-size: 13px;
}

.ui-dialog {
    left: 0;
    outline: 0 none;
    padding: 0 !important;
    position: absolute;
    top: 0;
    box-shadow: 0 5px 15px rgba(0,0,0,.5);
}

.ui-dialog .ui-widget-header.ui-dialog-titlebar {
    background: #1d74b1 !important;
    border: none !important;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    margin: 0;
    border-radius: 0;
}

.ui-dialog .ui-widget-header.ui-dialog-titlebar .ui-dialog-title {
    text-align: center;
    width: 100%;
}

.ui-dialog .ui-widget-header.ui-dialog-titlebar .ui-dialog-titlebar-close {
    right: 15px;
}

.ui-dialog .ui-dialog-content {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    overflow-x: hidden;
}

.ui-dialog.ui-corner-all {
    border-radius: 0;
}

.ui-datepicker {
    background: #fff;
    padding: 5px;
    border-color: #ddd;
    font-size: 14px;
    z-index: 2 !important;
    width: auto;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1)
}

.ui-datepicker .ui-datepicker-header {
    padding: .4em 0;
    border-radius: 0 !important;
    border: none!important;
    background: #f6fbff
}

.ui-datepicker-prev,
.ui-datepicker-next {
    cursor: pointer;
    background: none;
    width: 49%;
    float: left;
    color: #01335f;
    border: none;
    top: 10px!important;
}

.ui-datepicker-prev:hover,
.ui-datepicker-next:hover {
    background: #d3d3d3;
    text-decoration: none;
}

.ui-datepicker .ui-datepicker-title select {
    margin: 4px 0;
    padding: 2px 0;
    border-radius: 3px;
}

.ui-datepicker .ui-datepicker-prev:hover {
    left: 2px;
    background: #edf3f9;   
}

.ui-datepicker .ui-datepicker-next:hover {
    right: 2px;  
    background: #edf3f9;  
}

.ui-datepicker-month {
    margin-right: 4px !important;
}

.ui-datepicker-prev span:before {
    padding: 0 12px;
}

.ui-datepicker-next span:after {
    padding: 0 12px;
}

.ui-datepicker-title {
    text-align: center;
}

.ui-datepicker-calendar {
    text-align: center;
}

.ui-datepicker-calendar th {
    padding: 0.5625rem 0.625rem;
}

.ui-datepicker-calendar .ui-state-default {
    display: block;
    background: none;
    border: none;
    padding: 0.5625rem 0.625rem
}

.ui-datepicker-calendar .ui-state-default:hover {
    background: #b80007;
    color: #fff;
    text-decoration: none;
}

.ui-datepicker-title span,
.ui-datepicker-calendar th span {
    color: #666;
    font-size: 14px;
}

.ui-datepicker table th {
    background: #024f93;
    color: #fff
}

.ui-datepicker table tr:nth-of-type(even) {
    background: #f6fbff;
}

.ui-datepicker-title span, .ui-datepicker-calendar th span {
    color: #fff;
}

/*  Autocomplete */
.ui-autocomplete.ui-widget-content {
    background: #fff !important;
    font-size: 0.9em;
    padding: 0;
    margin: 0;
    max-height: inherit;
    box-shadow: 0 3px 6px rgba(0,0,0,0.4);
    overflow-y: hidden;
    overflow-x: hidden;
    z-index: 600;
    border-top-width: 3px;
    border-top-color: #da1921;
    margin-top: 9px;
}

.ui-autocomplete {
    list-style-type: none;
}

.ui-autocomplete li {
    list-style-type: none;
    font-family: Arial !important;
}

.ui-autocomplete.ui-widget-content li {
    color: #003d73 !important;
    font-size: 13px;
    line-height: 1.5em;
    list-style: none;
}

.ui-autocomplete.ui-widget-content li {
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    position: relative;
}

.ui-autocomplete.ui-widget-content li:not(.search-all):after {
    content: "\f105";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 40px;
    position: absolute;
    z-index: 1;
    right: 15px;
    top: 9px;
    color: #da1921
}

.ui-autocomplete.ui-widget-content li:hover,
.ui-autocomplete.ui-widget-content li.ui-state-focus {
    background: #edf3f9 !important;
    border: none;
    cursor: pointer;
}

#dropdown-megamenu2 li ul li a {
    padding: 10px 10px !important;
}

#dropdown-megamenu2 li ul li a:hover {
    background: #c0151e !important;
    color: #fff !important;
}

#dropdown-megamenu2 li ul li:last-child a:hover {
    border-bottom-left-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
}

.mobile-submenu {
    background: #0f6abb !important;
    border-bottom: 0 !important;
}

.mobile-submenu li {
    border-bottom: 1px solid #2580d1 !important;
}

.mobile-submenu .hs-has-sub-menu.g-py-5 {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    border-bottom: 10px solid #0f6abb !important;
    padding-top: 0 !important;
    margin-top: 0 !important;
    border-top: 10px solid #0f6abb !important;
}

.mobile-submenu .w-100.hs-mega-menu.u-shadow-v2.g-text-transform-none.g-bg-kpas-dark-blue.animated.hs-position-right.g-mt-10 {
    padding-top: 0 !important;
    margin-top: 0 !important;
}

body {
    overflow-x: hidden;
}

.info-window-link {
    cursor:pointer;
}


.table-bordered th {
    border: 1px solid #dee2e6 !important;
    text-align: center;
}

.align-center {
    text-align: center;
}

.word-counter.right {
    float: right !important;
    margin-top: .4em;

}

