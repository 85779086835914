
.plottable-colors-0 {
  background-color: #5279c7; /* INDIGO */
}

.plottable-colors-1 {
  background-color: #fd373e; /* CORAL_RED */
}

.plottable-colors-2 {
  background-color: #63c261; /* FERN */
}

.plottable-colors-3 {
  background-color: #fad419; /* BRIGHT_SUN */
}

.plottable-colors-4 {
  background-color: #2c2b6f; /* JACARTA */
}

.plottable-colors-5 {
  background-color: #ff7939; /* BURNING_ORANGE */
}

.plottable-colors-6 {
  background-color: #db2e65; /* CERISE_RED */
}

.plottable-colors-7 {
  background-color: #99ce50; /* CONIFER */
}

.plottable-colors-8 {
  background-color: #962565; /* ROYAL_HEATH */
}

.plottable-colors-9 {
  background-color: #06cccc; /* ROBINS_EGG_BLUE */
}

svg.plottable {
  display : block; /* SVGs must be block elements for width/height calculations to work in Firefox. */
  pointer-events: visibleFill;
}

.plottable .background-fill {
  fill: none;
  pointer-events: none;
}

.plottable .bounding-box {
  /* Invisible pink bounding-box to allow for collision testing */
  fill: pink;
  visibility: hidden;
}

.plottable .label text {
  font-family: "Helvetica Neue", sans-serif;
  fill: #32313F;
}

.plottable .bar-label-text-area text {
  font-family: "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.plottable .label-area text {
  fill: #32313F;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.plottable .light-label text {
  fill: white;
}

.plottable .dark-label text {
  fill: #32313F;
}

.plottable .off-bar-label text {
  fill: #32313F;
}

.plottable .stacked-bar-plot .off-bar-label {
  /* HACKHACK #2795: correct off-bar label logic to be implemented on StackedBar */
  visibility: hidden !important;
}

.plottable .axis-label text {
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: normal;
  text-transform: uppercase;
}

.plottable .title-label text {
  font-size: 20px;
  font-weight: bold;
}

.plottable .axis line.baseline {
  stroke: #CCC;
  stroke-width: 1px;
}

.plottable .axis line.tick-mark {
  stroke: #CCC;
  stroke-width: 1px;
}

.plottable .axis text {
  fill: #32313F;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 200;
  line-height: normal;
}

.plottable .axis .annotation-circle {
  fill: white;
  stroke-width: 1px;
  stroke: #CCC;
}

.plottable .axis .annotation-line {
  stroke: #CCC;
  stroke-width: 1px;
}

.plottable .axis .annotation-rect {
  stroke: #CCC;
  stroke-width: 1px;
  fill: white;
}

.plottable .bar-plot .baseline {
  stroke: #999;
}

.plottable .gridlines line {
  stroke: #3C3C3C; /* hackhack: gridlines should be solid; see #820 */
  opacity: 0.25;
  stroke-width: 1px;
}

.plottable .selection-box-layer .selection-area {
  fill: black;
  fill-opacity: 0.03;
  stroke: #CCC;
}
/* DragBoxLayer */
.plottable .drag-box-layer.x-resizable .drag-edge-lr {
  cursor: ew-resize;
}
.plottable .drag-box-layer.y-resizable .drag-edge-tb {
  cursor: ns-resize;
}

.plottable .drag-box-layer.x-resizable.y-resizable .drag-corner-tl {
  cursor: nwse-resize;
}
.plottable .drag-box-layer.x-resizable.y-resizable .drag-corner-tr {
  cursor: nesw-resize;
}
.plottable .drag-box-layer.x-resizable.y-resizable .drag-corner-bl {
  cursor: nesw-resize;
}
.plottable .drag-box-layer.x-resizable.y-resizable .drag-corner-br {
  cursor: nwse-resize;
}

.plottable .drag-box-layer.movable .selection-area {
  cursor: move; /* IE fallback */
  cursor: -moz-grab;
  cursor: -webkit-grab;
  cursor: grab;
}

.plottable .drag-box-layer.movable .selection-area:active {
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
/* /DragBoxLayer */

.plottable .guide-line-layer line.guide-line {
  stroke: #CCC;
  stroke-width: 1px;
}

.plottable .drag-line-layer.enabled.vertical line.drag-edge {
  cursor: ew-resize;
}

.plottable .drag-line-layer.enabled.horizontal line.drag-edge {
  cursor: ns-resize;
}

.plottable .legend text {
  fill: #32313F;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: normal;
}

.plottable .interpolated-color-legend rect.swatch-bounding-box {
  fill: none;
  stroke: #CCC;
  stroke-width: 1px;
  pointer-events: none;
}

.plottable .waterfall-plot line.connector {
  stroke: #CCC;
  stroke-width: 1px;
}

.plottable .pie-plot .arc.outline {
  stroke-linejoin: round;
}
